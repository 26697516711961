<template>

  <main class="contact" v-if="user">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>


          <router-link to="/zespol" title="Zespół" class="breadcrumb">
            Zespół
          </router-link>

          <router-link :to="user.url_full" :title="user.firstname +' '+user.lastname" class="breadcrumb">
            {{ user.firstname }} {{ user.lastname }}
          </router-link>
        </div>
      </div>


    </div>

    <div class="container">

      <div class="people">

        <div class="row">

          <div class="col s12 l4 xl3">

            <PeopleLine :user="user" :hide_name="true"/>
          </div>

          <div class="col s12 l8 xl9">

            <h3 class="people__name mt-0">{{user.firstname}} <b>{{user.lastname}}</b></h3>

            <p class="people__text">
              {{ user.text }}
            </p>

            <ul class="people__list">
              <li v-if="user.phone">
                <b>Telefon:</b>
                {{ user.phone }}
              </li>
              <li v-if="user.mail">
                <b>Mail:</b>
                {{ user.mail }}
              </li>
            </ul>

            <ul class="list-social">
              <li class="list-social__item" v-if="user.link_website">
                <a :href="user.link_website" target="_blank">
                  <i class="fas fa-globe"></i>
                </a>
              </li>
              <li class="list-social__item" v-if="user.link_twitter">
                <a :href="user.link_twitter" target="_blank">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li class="list-social__item" v-if="user.link_facebook">
                <a :href="user.link_facebook" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li class="list-social__item" v-if="user.link_instagram">
                <a :href="user.link_instagram" target="_blank">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>

          </div>

          <div class="col s12">

            <BroadcastUser :id_user="user.id"/>
            <PodcastUser :id_user="user.id"/>

          </div>
        </div>
      </div>

    </div>

    <br><br>

  </main>

</template>

<script>
import axios from "@/axios2";
import CoreArray from "@/helpers/array.js";
import PeopleLine from "./Part/Line";
import BroadcastUser from "../Audio/Part/BroadcastUser";
import PodcastUser from "../Audio/Part/PodcastUser";


export default {
  name: "PeopleShow",
  components: {PodcastUser, BroadcastUser, PeopleLine},
  props: ['slug'],
  data() {
    return {
      user: null,
      loaded: false,
    }
  },
  created() {
    this.load();
  },
  methods: {

    load: function () {

      axios
          .get('user/find', {
            params: {
              url: this.$route.params.slug,
              scope: 'id,firstname,lastname,url_full,avatar_url,text,position,phone,mail,link_website,link_twitter,link_facebook,link_instagram'
            }
          })
          .then(response => {

            this.user = response.data.data;

            let arr = [this.user];
            let column = "avatar_url";
            // let from = "https://off.radiokrakow.pl/api/";
            // let to = "https://off.radiokrakow.pl/";
            //
            // arr = CoreArray.changeDomain(arr,column,from,to);

            this.user = arr[0]

            this.loaded = true;

            let name = this.user.firstname+' '+this.user.lastname;

            this.$root.metaSet(name, this.user.text, this.user.avatar_url)

          });

    }
  },
  updated() {
  },
  watch: {

    '$route.params.slug': {
      handler: function (slug) {
        if (this.loaded) {
          this.load();
        }
        console.log(666)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-social {

  margin: 0 0 24px;
  padding: 0;

  list-style: none;

  & li{

    margin: 0 5px;
    padding: 0;

    display: inline-block;

    color: white;

    &:first-child{
      margin-left: 0;
    }

    & a{
      display: inline-block;
      color: inherit;
      font-weight: normal;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      //border: 1px solid #00000055;
      display: inline-block;

      text-align: center;
      line-height: 39px;

      background-color: #1d4bfe;
      color: #fff;

      &:hover{
        background-color: #1d4bfebb;
      }

    }

  }

}


</style>