<template>
  <section class="article-list" v-if="articles && articles.length > 0">
    <div class="row mb-0">
      <div class="col s12">
        <div class="title-line mb-4">

          <h2 class="big-title">
            Ostatnie podcasty
          </h2>

        </div>
      </div>
    </div>
    <div class="article-user-section">
      <div class="row row-db">
        <div class="col s12 xl6" v-for="article in articles">

          <PodcastLine :article="article"/>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import CoreArray from "@/helpers/array.js";
import axios from "@/axios2";

import PodcastLine from "@/views/Audio/Part/PodcastLine";
import {Carousel, Navigation, Slide} from "vue3-carousel";

export default {
  name: "PodcastUser",
  props: ['id_user'],
  components: {
    PodcastLine,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      articles: null,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1224: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    }
  },

  created: function () {


    axios
        .get('article/get', {
          params: {
            id_user_only: this.id_user,
            is_show: true,
            type: 'broadcast',
            count: 10,
            scope: 'title,block_thumb,thumb,date,url,audio,audio_url'
          }
        })
        .then(response => {

          let domain = "https://off.radiokrakow.pl/api/"
          let domain_new = "https://off.radiokrakow.pl/"

          let data = response.data;

          let articles = data.data;


          articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');
          // articles = CoreArray.changeDomain(articles, 'block_thumb', domain, domain_new);
          // articles = CoreArray.changeDomain(articles, 'audio_url', domain, domain_new);

          this.articles = articles;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>


.article-user-section .carousel {
  height: auto;
  overflow: visible !important;
}

.article-user-section .carousel__viewport {
  overflow-y: visible !important;

}

.article-user-section .carousel__slide {
  text-align: left;
  padding: 0 12px;
}

.article-user-section .carousel__slide .family-block {
  width: 100%;
}

.article-user-section .carousel__prev,
.article-user-section .carousel__next {

  top: -43px !important;
  background-color: transparent;
  position: absolute;
  color: #1d4bfe;

  display: block;
  left: auto;

  /*background-color: red!important;*/

  z-index: 200000;
}

.article-user-section .carousel__prev {
  right: 45px !important;
}

.article-user-section .carousel__next {
  right: 25px !important;
}

</style>