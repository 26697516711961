<template>
  <section class="family-list" v-if="familys && familys.length > 0">
      <div class="row">
      <div class="col s12">

          <h2 class="big-title">
            Prowadzone audycje
          </h2>

      </div>
      </div>
      <div class="row row-db">
        <div class="col s12 m6 l4" v-for="family in familys">
          <BroadcastBlock :family="family"/>
        </div>
      </div>
  </section>
</template>

<script>
import CoreArray from "@/helpers/array.js";
import axios from "@/axios2";

import BroadcastBlock from "./BroadcastBlock";

export default {
  name: "BroadcastUser",
  props: ['id_user'],
  components: {
    BroadcastBlock,
  },
  data() {
    return {
      familys: null
    }
  },

  created: function () {


    axios
        .get('articlefamily/get', {params: {id_user: this.id_user, type: 'broadcast', is_show: true,count: 4, scope: 'title,url,thumb,users', users_scope: 'firstname,lastname,name,avatar_url,url_full'}})
        .then(response => {

          let data = response.data;

          let familys = data.data;

          let domain = "https://off.radiokrakow.pl/api/"
          let domain_new = "https://off.radiokrakow.pl/"

          // familys = CoreArray.changeDomain(familys,'thumb',domain,domain_new);

          this.familys = familys;

        })
        .catch(e => {
          console.log(e)
        })

  }
}
</script>

<style>



</style>